<template>
  <div>
    <!-- <v-row no-gutters>
            <v-col cols="12" md="8">
                <div class="d-flex align-center">
                    <div class="mr-2 font-weight-medium" style="height: 26px">Phân phối theo:</div>
                    <v-radio-group v-model="type" row>
                        <v-radio v-for="item in typeOptions" :key="`t_${item.value}`" :label="item.text" :value="item.value"
                            :disabled="item.disabled"></v-radio>
                    </v-radio-group>
                </div>
            </v-col>
            <v-col cols="12" md="4">
                <div class="h-100 d-flex align-center justify-end">
                    <v-btn small color="primary" @click="showQuantityConfig">{{ $t('labels.config') }}</v-btn>
                </div>
            </v-col>
        </v-row> -->
    <TransferByLocation
      v-if="type === 1"
      f-type="pos"
      @onTypeChange="onTypeChange"
      @showQuantityConfig="showQuantityConfig"
    />
    <TransferByRate v-else-if="type === 2" />

    <v-dialog v-model="dialogQuantityConfig" max-width="1366px">
      <DialogQuantityConfig
        v-if="dialogQuantityConfig"
        @cancel="hideQuantityConfig"
      />
    </v-dialog>
  </div>
</template>

<script>
import { TRANSFER_TYPES } from "@/libs/const";
export default {
  components: {
    TransferByLocation: () =>
      import("@/components/pos_goods/TransferByLocation"),
    TransferByRate: () => import("@/components/pos_goods/TransferByRate"),
    DialogQuantityConfig: () =>
      import("@/components/pos_goods/DialogQuantityConfig"),
  },
  data: () => ({
    type: 1,
    typeOptions: [...TRANSFER_TYPES],
    dialogQuantityConfig: false,
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    showQuantityConfig() {
      this.dialogQuantityConfig = true;
    },
    hideQuantityConfig() {
      this.dialogQuantityConfig = false;
    },
    onTypeChange(type) {
      this.type = type;
    },
  },
};
</script>

<style scoped></style>
